<template>
  <div class="list-account-container">
    <div class="header-table">
      <div
        data-testid="title-list-ad-account"
        class="d-flex align-items-center justify-content-between mb-1 mb-md-2"
      >
        <h3
          class="font-medium mb-0"
          style="margin-bottom: 30px"
        >
          {{ $t('myAdAccountsPage.approvedTab.textListAccount') }}
        </h3>
        <btn-loading
          id="trigger-create-new-account"
          data-testid="button-create-new"
          pill
          variant-convert="btn-submit"
          @click="$emit('create-account')"
        >
          {{ $t('myAdAccountsPage.approvedTab.textCreateNewAccount') }}
        </btn-loading>
      </div>
      <div class="form-action">
        <!-- filter desktop -->
        <div class="filter-desktop">
          <div class="d-flex justify-content-end my-2 input-control pl-md-0 mt-1 mt-md-0">
            <btn-loading
              v-if="isPancakeUser"
              class="btn-submit-outline mr-2 pancake-btn"
              variant-convert="btn-submit-outline"
              pill
              @click="navigateToPancake"
            >
              {{ $t('payment.pancake.backToPancake') }}
            </btn-loading>
            <div
              class="status-input input-height-40 select-input"
            >
              <v-select
                v-model="statusSelected"
                data-testid="select-status-ad-account"
                class="input-height-40 custom-select-input"
                label="name"
                :options="statusOptions"
                :clearable="false"
                @input="handleStatusChange"
              >
                <template #open-indicator="{ attributes }">
                  <span
                    v-bind="attributes"
                    data-testid="chevron-down-icon"
                  >
                    <feather-icon
                      icon="ChevronDownIcon"
                      size="20"
                    />
                  </span>
                </template>
              </v-select>
            </div>
          </div>
        </div>
      <!-- / filter desktop -->
      </div>
    </div>

    <!-- table -->
    <vue-good-table
      id="list-ad-account-table"
      mode="remote"
      class="list-ad-account-table"
      :columns="columns"
      :rows="accounts"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: totalAccount > 0,
      }"
      :sort-options="{
        enabled: totalAccount > 0,
      }"
      :is-loading="loading"
      @on-sort-change="onSortChange"
    >

      <template
        slot-scope="props"
        slot="table-column"
      >
        <span
          v-if="props.column.field === 'autoTopup'"
          class="d-flex justify-content-between align-items-center"
        >
          <div class="d-flex align-items-center">
            <p
              data-testid="auto-topup-column"
              class="mb-0"
            >{{ props.column.label }}</p>
            <feather-icon
              id="tooltip-auto-top-up"
              icon="FileTextIcon"
              size="16"
              class="ml-25 text-success"
              style="min-width: 16px"
            />
            <b-tooltip
              target="tooltip-auto-top-up"
              triggers="hover"
              variant="light"
              data-bs-placement="top"
            >
              {{ $t('payment.balanceAllocationTable.txtTooltipAutoTopUp') }} <a
                href="https://docs.ecomdymedia.com/ecomdy-platform/feature/auto-topup/#1-auto-top-up-benefits"
                target="_blank"
                rel="noopener noreferrer"
                data-testid="link-view-detail"
                class="text-blue text-underline"
              >{{ $t('payment.balanceAllocationTable.textViewDetail') }}</a>
            </b-tooltip>
          </div>
        </span>

        <span
          v-else-if="props.column.field === 'ThresholdInformation'"
          class="d-flex justify-content-between align-items-center"
        >
          <div class="d-flex align-items-center">
            <p
              data-testid="threshold-column"
              class="mb-0"
            >{{ props.column.label }}</p>
            <feather-icon
              id="tooltip-threshold-information"
              icon="InfoIcon"
              size="16"
              class="ml-25 text-success"
              style="min-width: 16px"
            />
            <b-tooltip
              target="tooltip-threshold-information"
              triggers="hover"
              variant="light"
              data-bs-placement="top"
            >
              {{ $t('payment.balanceAllocationTable.txtTooltipThresholdInfo') }}
            </b-tooltip>
          </div>
        </span>
      </template>

      <template
        slot-scope="props"
        slot="table-row"
      >
        <!-- Column: Ad Account -->
        <div
          v-if="props.column.field === '_id'"
        >
          <div class="d-flex align-items-center">
            <b-img
              class="ads-avt-img"
              :src="adsAccountImg"
            />
            <div
              v-b-tooltip.hover.top.v-light.html
              class="d-flex flex-column content-ad-account"
              :title="`<span class='underline'>${props.row.name}</span></br>${props.row._id}`"
            >
              <b-link
                data-testid="ad-account-name"
                class="underline text-primary font-weight-600 wrap-content"
                :href="
                  `https://ads.tiktok.com/i18n/dashboard?aadvid=${props.row._id}`
                "
                target="_blank"
                rel="noreferrer noopener"
              >
                {{ props.row.name }}
              </b-link>
              <span
                data-testid="ad-account-id"
                class="text-placeholder wrap-content"
              >ID: {{ props.row._id }}</span>
            </div>
          </div>
        </div>

        <!-- Column: Business Account -->
        <div
          v-else-if="props.column.field === 'BCId'"
          class="d-flex align-items-center"
        >
          <b-img
            rounded
            class="ads-avt-img"
            :src="baAdsAccountImg"
          />
          <div
            data-testid="partner-BCId"
            class="d-flex flex-column content-ad-account"
          >
            <p
              v-b-tooltip.hover.top.v-light="props.row.partnerBCId"
              class="text-primary font-weight-600 mb-0 wrap-content"
            >ID: {{ props.row.partnerBCId }}</p>
          </div>
        </div>

        <!-- Column: timezone -->
        <span
          v-else-if="props.column.field === 'timezone'"
          data-testid="timezone-value"
        >
          <span v-if="props.row.displayTimezone">
            {{ props.row.displayTimezone }}
          </span>
          <span v-else>{{ props.row.timezone }}</span>
        </span>

        <!-- Column: Cost -->
        <span
          v-else-if="props.column.field === 'report.cost'"
          data-testid="cost-value"
        >
          <span v-if="props.row.report && props.row.report.cost">
            ${{ amountFormat(props.row.report.cost) }}
          </span>
          <span v-else>N/A</span>
        </span>

        <!-- Column: Balance -->
        <span
          v-else-if="props.column.field === 'balance'"
          data-testid="balance-value"
        >
          ${{ amountFormat(props.row.balance) }}
        </span>

        <!-- Column: Auto Topup -->
        <div
          v-else-if="props.column.field === 'autoTopup'"
          class="d-flex align-items-center"
        >
          <b-form-checkbox
            :id="props.row._id"
            data-testid="toggle-auto-topup"
            class="custom-control-success"
            name="check-button"
            switch
            inline
            :checked="selectedAutoTopUp(props.row)"
            :disabled="disableToggle(props.row)"
            :class="disableToggle(props.row) ? 'toggle-disabled' : ''"
            @change="onChangeAutoTopup('add', $event,props.row)"
          />

          <div v-if="selectedAutoTopUp(props.row)">
            <p
              data-testid="btn-edit-auto-topup"
              class="btn-edit"
              @click="() => {
                if(!disableToggle(props.row) && !isNotApprove(props.row.status)) {
                  editAutoTopUp(`${selectedAutoTopUp(props.row) ? 'edit' : 'add' }`, props.row)
                }
              }"
            >
              {{ $t('autoTopUp.setupAutomation.edit') }}
            </p>
          </div>

        </div>

        <div v-else-if="props.column.field === 'ThresholdInformation'">
          <div class="column-threshold-info">
            <div class="amount-top-up-auto">
              <div class="d-flex align-items-center">
                <b-img
                  class="ic-top-up-amount"
                  :src="require('@/assets/images/pages/payment/ic-top-up-amount.svg')"
                  alt="top-up amount"
                />
                <p
                  data-testid="text-amount-auto-topup"
                  class="m-0"
                >
                  $ {{ amountTopUpAuto(props.row) }}
                </p>
              </div>

              <div class="icon-container">
                <feather-icon
                  v-b-tooltip.top.v-light="$t('myAdAccountsPage.approvedTab.topUpAmount')"
                  id="add-fund-at-payment-child-2"
                  data-testid="icon-tooltip-topup-amount"
                  icon="InfoIcon"
                  size="14"
                  style="margin-top: -5px; margin-left: 2px"
                />
              </div>
            </div>

            <div class="amount-threshold">
              <div class="d-flex align-items-center">
                <b-img
                  class="ic-top-up-amount"
                  :src="require('@/assets/images/pages/payment/ic-amount-threshold.svg')"
                  alt="top-up amount"
                />
                <p
                  data-testid="text-amount-threshold"
                  class="m-0"
                >
                  $ {{ amountThresholdAuto(props.row) }}
                </p>
              </div>

              <div class="icon-container">
                <feather-icon
                  v-b-tooltip.top.v-light="$t('myAdAccountsPage.approvedTab.amountThreshold')"
                  id="add-fund-at-payment-child-2"
                  data-testid="icon-tooltip-amount-threshold"
                  icon="InfoIcon"
                  size="14"
                  style="margin-top: -5px; margin-left: 2px"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Column: status -->
        <span
          v-else-if="props.column.field === 'status'"
          data-testid="status-ad-account"
          class="text-nowrap text-capitalize"
        >
          <b-badge
            :variant="adsStatusVariant(props.row.status)"
            class="font-14"
          >
            {{ adsStatusTxt(props.row.status) }}
          </b-badge>
        </span>

        <div v-else-if="props.column.field === 'action'">
          <div class="d-flex flex-wrap flex-column align-items-start mt-1 width-max">
            <div
              v-b-tooltip.hover.top.v-light="isNotApprove(props.row.status) ? contentToolTip('disabled add fund') : ''"
              class="mb-1 w-100"
            >
              <btn-loading
                id="add-fund-at-my-ads-account"
                class="btn-add-fund w-100"
                variant-convert="btn-submit-outline"
                :class="isNotApprove(props.row.status)? 'disable-btn' : 'text-action'"
                @click="() => {
                  if(!isNotApprove(props.row.status)) {
                    onModalClick('RECHARGE', props.row)
                  }
                }"
              >
                <div class="d-flex align-items-center">
                  <feather-icon
                    class="button__icon"
                    icon="PlusCircleIcon"
                    size="14"
                    style="margin-right:2px;"
                  />
                  <p class="mb-0 button__label">
                    {{ $t('layout.btnAddFund') }}
                  </p>
                </div>
              </btn-loading>
            </div>

            <div
              v-b-tooltip.hover.top.v-light="isNotApprove(props.row.status) ? contentToolTip('disabled withdraw') : ''"
              class="w-100"
            >
              <btn-loading
                id="withdraw-at-my-ads-account"
                class="btn-add-fund w-100"
                variant-convert="btn-submit-outline"
                :class="isNotApprove(props.row.status) || formatAmount(props.row.balance, props.row.currency) <= 0 ? 'disable-btn' : 'text-action'"
                @click="() => {
                  if(!isNotApprove(props.row.status) && formatAmount(props.row.balance, props.row.currency) > 0) {
                    onModalClick('REFUND', props.row)
                  }
                }"
              >
                <div class="d-flex align-items-center">
                  <feather-icon
                    class="button__icon"
                    icon="MinusCircleIcon"
                    size="14"
                    style="margin-right:2px;"
                  />
                  <p class="mb-0 button__label">
                    {{ $t('balance.textWithdraw') }}
                  </p>
                </div>
              </btn-loading>
            </div>
          </div>
        </div>

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        v-if="totalAccount > 0"
        slot="pagination-bottom"
      >
        <!--        <div class="footer-table">-->
        <!--          <span-->
        <!--            class="text-secondary font-weight-600"-->
        <!--          >{{ totalAccount }} {{ $t('myAdAccountsPage.approvedTab.textAccountAvailable') }}</span>-->
        <!--        </div>-->

        <b-row class="d-flex flex-wrap mt-1 footer-tab px-1">
          <b-col
            md="6"
            class="d-flex align-items-center"
          >
            <div
              data-testid="text-per-page"
              class="d-flex align-items-center mb-0"
            >
              <span class="text-nowrap d-none d-md-block">
                {{ $t('myAdAccountsPage.approvedTab.textAccountPerPage') }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                data-testid="select-per-page"
                class="mx-1 d-none d-md-block"
                @input="handlePageChange"
              />
              <span
                data-testid="text-show-per-page"
                class="text-nowrap"
              >
                {{ $t('myAdAccountsPage.approvedTab.textShow') }}
                {{ pageLength * (currentPage - 1) + 1 }}
                {{ $t('common.textTo') }}
                {{ pageLength * currentPage }}
                {{ $t('common.textOf') }} {{ totalAccount }} {{ $t('myAdAccountsPage.approvedTab.textAccount') }}
              </span>
            </div>
          </b-col>
          <!-- page length -->
          <b-col
            v-if="totalAccount > pageLength"
            md="6"
          >
            <b-pagination
              :total-rows="totalAccount"
              :value="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              data-testid="pagination-nav"
              class="mb-0 pagination-table"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  data-testid="chevron-left-icon"
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  data-testid="chevron-right-icon"
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </template>

      <!-- custom loading  -->
      <template slot="loadingContent">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
      </template>

      <div
        slot="emptystate"
        class="text-center"
      >
        <no-data-table-image class="my-5" />
      </div>
    </vue-good-table>

    <add-fund-modal
      ref="open-handle-balance-account-modal"
      :open-add-fund-modal="openAddFundModal"
      :account="accountSelected"
      :user-info="user"
      @fetch-ads-accounts="fetchAdsAccounts"
    />

    <setup-automation-modal
      ref="open-setup-automation-modal"
      :account-selected="accountSelected"
      :action="action"
      @fetchAdsAccounts="fetchAdsAccounts"
      @cancelModal="cancelModal"
    />

    <no-money-balance-modal
      ref="open-no-money-balance-modal"
      @cancelModal="cancelModal"
    />

    <confirm-turn-off-auto-top-up-modal
      ref="open-confirm-turn-off-auto-top-up-modal"
      :account-selected="accountSelected"
      @fetchAdsAccounts="fetchAdsAccounts"
      @cancelModal="cancelModal"
    />
  </div>
</template>
<script>
/* eslint-disable global-require */
/* eslint-disable no-underscore-dangle */
import {
  BPagination,
  BSpinner,
  BImg,
  BLink,
  BFormSelect,
  BBadge,
  BFormCheckbox,
  VBTooltip,
  BTooltip,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { createNamespacedHelpers } from 'vuex'
import moment from 'moment'
import { ADS_ACCOUNT_STATUS } from '@/constants'
import get from 'lodash/get'
import billingMixin from '@/mixins/billingMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import NoDataTableImage from '@/components/NoDataTableImage.vue'
import AddFundModal from '@/views/payment/components/TabsHistory/Modal/AddFundModal.vue'
import vSelect from 'vue-select'
import envMixin from '@/mixins/envMixin'
import SetupAutomationModal from '@/views/payment/components/TabsHistory/Modal/SetupAutomationModal.vue'
import NoMoneyBalanceModal from '@/views/payment/components/TabsHistory/Modal/NoMoneyBalanceModal.vue'
import ConfirmTurnOffAutoTopUpModal from '@/views/payment/components/TabsHistory/Modal/ConfirmTurnOffAutoTopUpModal.vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import authMixin from '@/mixins/authMixin'

const { mapActions, mapGetters } = createNamespacedHelpers('adsAccount')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    BtnLoading,
    ConfirmTurnOffAutoTopUpModal,
    NoMoneyBalanceModal,
    SetupAutomationModal,
    NoDataTableImage,
    BPagination,
    BSpinner,
    BImg,
    BLink,
    BFormSelect,
    BBadge,
    BFormCheckbox,
    // components
    VueGoodTable,
    vSelect,
    AddFundModal,
    BTooltip,
    BRow,
    BCol,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [numberFormatMixin, generalConfigsMixin, billingMixin, envMixin, authMixin],

  data() {
    return {
      accountSelected: {},
      action: '',
      show: false,

      currentPage: 1,
      searchTerm: '',

      // sort
      sortTerm: {
        field: '',
        type: '',
      },

      // per page
      pageLength: 10,
      pages: ['10', '20', '50', '100'],

      openAddFundModal: false,

      statusSelected: {
        name: this.$t('payment.balanceTransactionsTable.textAllStatus'),
        value: '',
      },
      statusOptions: [
        {
          name: this.$t('payment.balanceTransactionsTable.textAllStatus'),
          value: '',
        },
        {
          name: this.$t('myAdAccountsPage.textApproved'),
          value: ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_APPROVED,
        },
        {
          name: this.$t('myAdAccountsPage.textNotApproved'),
          value: ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_NOT_APPROVED,
        },
        {
          name: this.$t('myAdAccountsPage.textPunishment'),
          value: ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_PUNISHED,
        },
        {
          name: this.$t('myAdAccountsPage.textReview'),
          value: ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_IN_REVIEW,
        },
        {
          name: this.$t('myAdAccountsPage.textNotYetEffective'),
          value: ADS_ACCOUNT_STATUS.CONTRACT_NOT_YET_EFFECTIVE,
        },
        {
          name: this.$t('myAdAccountsPage.textClosed'),
          value: ADS_ACCOUNT_STATUS.CLOSED,
        },
      ],

      isScrollTable: false,
    }
  },

  computed: {
    ...mapGettersAuth(['user']),
    ...mapGetters(['listAccount', 'message', 'status', 'loading']),

    amountTopUpAuto() {
      return account => account?.autoTopUpSetting?.amount || '---'
    },

    amountThresholdAuto() {
      return account => account?.autoTopUpSetting?.threshold || '---'
    },

    columns() {
      return [
        {
          label: this.$t('account.ad'),
          field: '_id',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-column-id',
        },
        {
          label: this.$t('account.businessAccount'),
          field: 'BCId',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-column-id',
        },
        {
          label: this.$t('payment.balanceAllocationTable.adAccountStatusColumn'),
          field: 'status',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
          width: '120px',
          sortable: false,
        },
        {
          label: this.$t('account.timeZone'),
          field: 'timezone',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('account.cost'),
          field: 'report.cost',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('account.balance'),
          field: 'balance',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('payment.balanceAllocationTable.autoTopUp'),
          field: 'autoTopup',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
          hidden: !this.autoTopUpAdAccountMode || this.isExclusivePartner,
          sortable: false,
          width: '110px',
        },
        {
          label: this.$t('payment.balanceAllocationTable.thresholdInformation'),
          field: 'ThresholdInformation',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
          hidden: !this.autoTopUpAdAccountMode || this.isExclusivePartner,
          sortable: false,
          width: '150px',
        },
        {
          label: this.$t('payment.balanceAllocationTable.action'),
          field: 'action',
          thClass: `p-1 border-0 header-table-mf th-action ${this.isScrollTable && this.accounts?.length > 0 ? 'scroll-action-column' : ''}`,
          tdClass: `p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-action ${this.isScrollTable && this.accounts?.length > 0 ? 'scroll-action-column' : ''}`,
          sortable: false,
        },
      ]
    },

    isNotApprove() {
      return value => value !== ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_APPROVED
    },

    amountBalance() {
      return this.user?.data?.balance
    },

    contentToolTip() {
      return type => {
        if (type === 'add') {
          return `${this.$t('payment.balanceAllocationTable.textTransferMoneysFrom', { platformName: this.platformName })}`
        }
        if (type === 'disabled add fund') {
          return `${this.$t('payment.balanceAllocationTable.textTooltipNotAddFund', { platform: this.platformName })}`
        }
        if (type === 'disabled withdraw') {
          return `${this.$t('payment.balanceAllocationTable.textTooltipNotWithdraw', { platform: this.platformName })}`
        }
        if (type === 'disabled auto top-up') {
          return `${this.$t('payment.balanceAllocationTable.textTooltipNotAutoTopUp', { platform: this.platformName })}`
        }
        return `${this.$t('payment.balanceAllocationTable.contentTooltipWithdraw', { platformName: this.platformName })}`
      }
    },

    accounts() {
      return get(this.listAccount, ['content'], [])
    },

    totalAccount() {
      return get(this.listAccount, ['paging', 'total'], 0)
    },

    adsStatusVariant() {
      const statusColor = {
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_APPROVED]: 'light-success',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_NOT_APPROVED]: 'light-warning',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_PUNISHED]: 'light-danger',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_IN_REVIEW]: 'light-info',
      }

      return status => statusColor[status]
    },

    adsStatusTxt() {
      const statusColor = {
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_APPROVED]: this.$t('myAdAccountsPage.textApproved'),
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_NOT_APPROVED]: this.$t('myAdAccountsPage.textNotApproved'),
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_PUNISHED]: this.$t('myAdAccountsPage.textPunishment'),
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_IN_REVIEW]: this.$t('myAdAccountsPage.textReview'),
        [ADS_ACCOUNT_STATUS.CLOSED]: this.$t('payment.statusClosed'),
        [ADS_ACCOUNT_STATUS.CONTRACT_NOT_YET_EFFECTIVE]: this.$t('myAdAccountsPage.textNotYetEffective'),
      }

      return status => statusColor[status]
    },

    alertTextColor() {
      const txtColor = {
        [true]: 'text-danger',
        [false]: 'text-success',
      }

      return txt => txtColor[txt]
    },

    adsAccountImg() {
      if (this.isEmediaPlatform) {
        return require('@/assets/images/common/ic-ads-acc-emedia.svg')
      }
      return require('@/assets/images/common/ic-ads-acc.svg')
    },

    baAdsAccountImg() {
      if (this.isEmediaPlatform) {
        return require('@/assets/images/common/ic-ba-acc-emedia.svg')
      }
      return require('@/assets/images/common/ic-ba-acc.svg')
    },

    createdDate() {
      return value => (value ? new Date(value).toLocaleString('en-GB') : '')
    },

    selectedAutoTopUp() {
      return data => data.autoTopUpSetting && data.autoTopUpSetting.status === 'enable'
    },

    disableToggle() {
      return data => data.status !== ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_APPROVED
    },
  },

  watch: {
    $route: {
      handler(route) {
        if (route?.query?.tab === 'approved') {
          this.fetchAdsAccounts()
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    this.initScrollVerifyInfoTable()
  },

  mounted() {
    this.$nextTick(() => {
      this.checkHasScrollbar()
    })
  },

  methods: {
    ...mapActions(['getAccountList']),

    checkHasScrollbar() {
      const content = document.querySelector('#list-ad-account-table .vgt-responsive')
      const widthNavBar = 1241
      const hasScrollBar = widthNavBar > content.clientWidth

      if (hasScrollBar) {
        this.isScrollTable = true
      }
    },

    async initScrollVerifyInfoTable() {
      await document.querySelector('#list-ad-account-table')
      document.querySelector('#list-ad-account-table .vgt-responsive').addEventListener('scroll', event => this.handleScrollBar(event))
    },

    handleScrollBar() {
      const content = document.querySelector('.vgt-responsive')
      if (content) {
        const scrollEnd = content.scrollWidth - content.clientWidth
        const scrollLeftRounded = Math.round(content.scrollLeft)

        // Kiểm tra nếu đã cuộn đến cuối
        this.isScrollTable = scrollLeftRounded !== Math.round(scrollEnd - 0.5) && scrollLeftRounded !== scrollEnd
      }
    },

    onModalClick(type, data) {
      this.$refs['open-handle-balance-account-modal'].showModal()
      this.accountSelected = data
      this.openAddFundModal = type === 'RECHARGE' //  [RECHARGE, REFUND]
    },

    async fetchAdsAccounts() {
      const params = {
        page: this.currentPage,
        size: this.pageLength,
        ...(this.sortTerm.field && {
          sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${
            this.sortTerm.field
          }`,
        }),
        ...(this.searchTerm && { keyword: this.searchTerm }),
        ...(this.statusSelected.value && { status: this.statusSelected.value }),
        // ...this.rangeDates,
      }
      await this.getAccountList(params)
    },

    async handleSearch(searching) {
      if (this.debounce !== null) {
        await clearTimeout(this.debounce)
      }

      if (searching === '') {
        this.clearSearch()
      } else if (searching.length > 2) {
        this.currentPage = 1
        this.debounce = setTimeout(() => {
          this.fetchAdsAccounts()
        }, 600)
      }
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchAdsAccounts()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchAdsAccounts()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchAdsAccounts()
    },

    async handleDateChange(selectedDates) {
      if (selectedDates.length === 2) {
        // check if user select start and end date already.
        this.currentPage = 1
        this.dateSelected = null
        this.startDate = moment(new Date(selectedDates[0])).format('YYYY-MM-DD')
        this.endDate = moment(new Date(selectedDates[1])).format('YYYY-MM-DD')
        await this.fetchAdsAccounts()
      }
    },

    async clearDate() {
      await this.fetchAdsAccounts()
    },

    clearSearch() {
      this.searchTerm = ''
      this.currentPage = 1
      this.fetchAdsAccounts()
    },

    async handleStatusChange() {
      this.currentPage = 1
      await this.fetchAdsAccounts()
    },

    formatAmount(balance, currency) {
      const rate = this.USDToVNDRate
      return currency === 'VND' ? this.amountFormat(balance / rate) : this.amountFormat(balance)
    },

    // auto top-up
    cancelModal(checked) {
      if (document.getElementById(this.accountSelected._id)) {
        document.getElementById(this.accountSelected._id).checked = checked
      }
    },

    onChangeAutoTopup(action, event, data) {
      this.action = action
      this.accountSelected = data
      if (event) {
        if (this.user.data.balance === 0) {
          this.$refs['open-no-money-balance-modal'].showModal()
        } else {
          this.$refs['open-setup-automation-modal'].showModal()
        }
      } else {
        this.$refs['open-confirm-turn-off-auto-top-up-modal'].showModal()
      }
    },

    editAutoTopUp(action, data) {
      this.action = action
      this.accountSelected = data
      this.$refs['open-setup-automation-modal'].showModal()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.list-ad-account-table {

  .td-column-id {
    max-width: 200px;
  }

  tr:hover {
    .action-column {
      z-index: 9;
    }
  }

  .dropdown-toggle {
    padding: 1rem;
  }

  .th-action {
    position: sticky !important;
    z-index: 1;
    right: 0;
  }

  .td-action {
    background-color: #fff;
    min-width: 150px;
    position: sticky !important;
    z-index: 1;
    right: 0;
  }

  .scroll-action-column {
    &:after {
      content: "";
      position: absolute;
      height: 101%;
      top: 0;
      left: -13px;
      box-shadow: inset 13px 0px 8px -8px #00000026;
    }
  }

  .vgt-responsive {
    overflow-y: clip;
  }
}

.ads-avt-img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}
.close-icon {
  .input-group-text {
    padding-left: 0;
    padding-right: 0;
  }
}
.badge {
  border-radius: 100px;
  padding: 6px 12px;
  font-weight: 600;
}

.input-group-merge{
  .form-control:not(:first-child) {
    padding: 0;
  }

  .input-group-text {
    @media(max-width: 840px) {
      padding: 0.438rem 10px;
    }
  }
}

.list-account-container {
  .vgt-table {
    border: 0px;

    .vgt-responsive {
      overflow-y: hidden;
    }
  }
}

.toggle-disabled {
   .custom-control-label:before, .custom-control-label:after {
      cursor: not-allowed !important;
   }
  .custom-control-input:active ~ .custom-control-label::before  {
    background-color: #efefef !important;
  }
}

.custom-select-input {
  .vs__dropdown-toggle {
    align-items: center;
  }

  @media (max-width: 767px) {
    .vs__selected-options {
      font-size: 12px
    }

    .vs__dropdown-menu {
      font-size: 12px
    }
  }
}
</style>

<style lang="scss" scoped>
.select-input {
  min-width: 250px;
  width: 250px;
  @media (max-width: 767px) {
    min-width: 150px;
    width: 150px;
  }
}

.column-threshold-info {

  .amount-top-up-auto {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;

    .ic-top-up-amount {
      margin-right: 6px;
    }

    .icon-container {
      margin-left: 1rem;
    }
  }

  .amount-threshold {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ic-top-up-amount {
      margin-right: 6px;
    }

    .icon-container {
      margin-left: 1rem;
    }
  }
}

.btn-add-fund {
  padding: 6px 12px;
  border-radius: 8px !important;
  width: max-content;
}

.btn-edit {
  font-weight: 600;
  margin: 0;
  cursor: pointer;
  color: #2667FF;
}

.disable-btn {
  opacity: .5;
  cursor: not-allowed !important;

  &:hover {
    box-shadow: none !important;
  }
}

.content-ad-account {
  width: calc(100% - 54px);

  .wrap-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.list-account-container {
  background-color: #fff;
  padding: 24px 0;
  border-radius: var(--border-radius-base);
}

.header-table {
  padding: 0 24px;
}

.btn-disabled {
  color: rgba(22, 33, 62, 0.7);
  border: 1px solid rgba(22, 33, 62, 0.7) !important;
}

.btn-new {
  padding: 0 9px;
  background: linear-gradient(128.33deg, #81fff4 -29.89%, #6e44ff 98.77%);
  color: #fff;
  border-radius: 100px;
  font-size: 12px;
}

.pancake-btn {
  min-width: 170px;

  @media (max-width: 767px) {
    min-width: 140px;
    width: 140px;
  }
}
</style>
